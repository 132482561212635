import React, { useState, useEffect } from 'react';
import './App.css';

const quotes = [
  { author: "Albert Einstein", text: "The only source of knowledge is experience." },
  { author: "Maya Angelou", text: "Try to be a rainbow in someone's cloud." },
  { author: "Mark Twain", text: "The two most important days in your life are the day you are born and the day you find out why." },
  { author: "Socrates", text: "The unexamined life is not worth living." },
  { author: "Confucius", text: "Our greatest glory is not in never falling, but in rising every time we fall." },
  // ... other quotes
];

const styles = {
  app: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#282c34',
    color: 'white',
    fontSize: 'calc(10px + 2vmin)',
    textAlign: 'center',
  },
  quoteContainer: {
    maxWidth: '60%',
  },
  quoteText: {
    marginBottom: '20px',
    fontStyle: 'italic',
  },
  quoteAuthor: {
    fontWeight: 'bold',
  }
}


function App() {
  const [quote, setQuote] = useState({ author: '', text: '' });

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setQuote(quotes[randomIndex]);
  }, []);

  return (
      // @ts-ignore
      <div className="App" style={styles.app}>
        <div style={styles.quoteContainer}>
          <p style={styles.quoteText}>“{quote.text}”</p>
          <p style={styles.quoteAuthor}>- {quote.author}</p>
        </div>
      </div>
  );
}


export default App;
